/* eslint-disable unicorn/filename-case */
/* eslint-disable react/button-has-type */
import { useState } from "react";
import { useInternalLinks } from "../../graphql/hooks/use-internallinks"
import { ChevronDown, ChevronUp } from "lucide-react";


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const InternalLinksExplorer = () => {
    const {allStrapiInternalLink} = useInternalLinks();

    const [openSections, setOpenSections] = useState<Record<string, boolean>>(
      allStrapiInternalLink.nodes[0].ImportantLinks.reduce((acc, section) => ({
        ...acc,
        [section.id]: false
      }), {})
    )
   

    if(!allStrapiInternalLink.nodes[0]) return null



      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const toggleSection = (sectionId: string) => {
        setOpenSections(prev => ({
          ...prev,
          [sectionId]: !prev[sectionId]
        }))
      }
      const internalLinks = allStrapiInternalLink.nodes[0].ImportantLinks

      if(!internalLinks.length) return null

  return (
    <div className="container px-6 mx-auto pb-7 py-8 space-y-6">
        <h6 className="text-2xl font-bold pb-6 max-lg:text-2xl text-center">Importants Links</h6>
      {internalLinks.map((section) => (
        <div 
          className=" overflow-hidden"
          key={section.id}
        >
          <button
            className="w-full flex justify-between items-center p-4 bg-gray-50 hover:bg-gray-100"
            onClick={() => { toggleSection(section.id); }}
          >
            <h2 className="text-[16px] font-bold text-gray-900">
              {section.subtitle}
            </h2>
            {openSections[section.id] ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </button>
          
          {openSections[section.id] ? <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4 bg-white">
              {section.links.map((link) => (
                <a
                  className="block p-3 hover:bg-gray-50 transition-colors duration-200 border border-gray-200 rounded-lg"
                  href={link.URL}
                  key={link.URL}
                >
                  <span className="text-gray-700">{link.Name}</span>
                </a>
              ))}
            </div> : null}
            {/* <div className="border-b border-solid border-gray-200" /> */}
        </div>
      ))}
    </div>
  )
}

export default InternalLinksExplorer