import { graphql, useStaticQuery } from 'gatsby';

export interface LinkItem {
  Name: string;
  URL: string;
}

export interface ImportantLink {
  id: string;
  links: LinkItem[];
  subtitle: string;
}

export interface InternalLinksProps {
    allStrapiInternalLink: {
        nodes: {
          id: string;
          ImportantLinks: ImportantLink[];
        }[];
      };
}

export const useInternalLinks: () => InternalLinksProps = () => {
  const data = useStaticQuery<InternalLinksProps>(graphql`
   query AllInternalLinks {
  allStrapiInternalLink {
    nodes {
      id
      ImportantLinks {
        id
        links {
          Name
          URL
        }
        subtitle
      }
    }
  }
}
    `);

  return data;
};
